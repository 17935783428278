import React, { ReactElement } from "react";

export default function CreditTentLogo(): ReactElement {
    return (
        <svg
            width="273"
            height="35"
            viewBox="0 0 273 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_23_651_z)">
                <path
                    d="M55.8052 29.1495C54.2859 29.1861 52.7745 28.9196 51.3593 28.3656C49.9442 27.8117 48.6535 26.9813 47.5628 25.923C46.4534 24.8424 45.5818 23.542 45.0039 22.1051C44.426 20.6682 44.1544 19.1265 44.2065 17.5787C44.155 16.0127 44.4329 14.4533 45.0222 13.0015C45.6116 11.5497 46.4992 10.2378 47.6277 9.15084C48.7117 8.0719 50.0017 7.22203 51.4209 6.65181C52.8401 6.08159 54.3595 5.80266 55.8886 5.83164C57.8647 5.81023 59.8149 6.28185 61.5628 7.20382C63.2129 8.00665 64.6159 9.23984 65.6237 10.7734L63.3708 12.6277C62.551 11.419 61.4444 10.4324 60.1501 9.7561C58.8557 9.07979 57.4139 8.73486 55.9535 8.75217C54.8199 8.73309 53.6943 8.94522 52.6454 9.37562C51.5965 9.80602 50.6464 10.4457 49.8529 11.2555C49.0268 12.0813 48.3785 13.0676 47.948 14.1534C47.5175 15.2393 47.3138 16.4018 47.3496 17.5694C47.3108 18.7061 47.5061 19.8386 47.9233 20.8967C48.3406 21.9547 48.9709 22.9157 49.7752 23.7199C50.5794 24.5242 51.5404 25.1545 52.5984 25.5718C53.6565 25.989 54.789 26.1843 55.9257 26.1455C57.3896 26.1513 58.8333 25.8046 60.135 25.1349C61.4441 24.465 62.5899 23.5154 63.4913 22.3535L65.6886 24.2078C64.591 25.7241 63.1397 26.9494 61.4608 27.7773C59.7103 28.6747 57.7723 29.1449 55.8052 29.1495V29.1495Z"
                    fill="#0482B0"
                />
                <path
                    d="M71.6598 28.8158H68.7393V12.4701H71.5207V14.8807C71.9295 13.9903 72.596 13.2431 73.434 12.7357C74.272 12.2283 75.243 11.9839 76.2214 12.0343C76.8687 12.0424 77.5128 12.1264 78.1406 12.2846L77.8624 15.1217C77.3114 14.9422 76.736 14.8483 76.1565 14.8436C75.5482 14.8218 74.9426 14.9333 74.382 15.1703C73.8214 15.4074 73.3195 15.7642 72.9114 16.2158C72.0107 17.3308 71.5637 18.7445 71.6598 20.1747V28.8158Z"
                    fill="#0482B0"
                />
                <path
                    d="M95.033 20.3325C95.033 20.5364 95.033 21.0371 94.9402 21.8345H81.9138C82.0837 23.2 82.7769 24.4463 83.8474 25.311C84.9179 26.1756 86.2821 26.5912 87.6528 26.4702C88.6773 26.4623 89.6941 26.2934 90.6661 25.9696C91.6271 25.6734 92.5348 25.2258 93.3548 24.6437L94.6065 26.8689C93.6438 27.5837 92.5734 28.1408 91.4356 28.5192C90.1988 28.9519 88.8982 29.1744 87.5879 29.1775C86.2357 29.1993 84.8928 28.9503 83.6383 28.445C82.5771 28.019 81.6376 27.3374 80.9032 26.4609C80.2389 25.629 79.7243 24.6878 79.3826 23.6795C79.0363 22.6679 78.8609 21.6057 78.8634 20.5364C78.8137 18.291 79.6496 16.1162 81.1906 14.4821C81.9614 13.6819 82.8926 13.0535 83.9232 12.6381C84.9537 12.2227 86.0604 12.0296 87.1707 12.0715C88.2187 12.0326 89.2634 12.2101 90.2396 12.593C91.2159 12.9758 92.1028 13.5558 92.8449 14.2967C93.5988 15.1025 94.1815 16.0529 94.5576 17.0903C94.9337 18.1278 95.0954 19.2307 95.033 20.3325ZM81.9138 19.3033H92.0105C92.0048 18.6756 91.873 18.0555 91.6228 17.4798C91.3727 16.9041 91.0093 16.3845 90.5544 15.9521C90.0994 15.5196 89.5621 15.183 88.9745 14.9623C88.3868 14.7417 87.7608 14.6414 87.1336 14.6676C85.869 14.6456 84.6442 15.1103 83.7124 15.9656C82.7527 16.8427 82.1186 18.0193 81.9138 19.3033V19.3033Z"
                    fill="#0482B0"
                />
                <path
                    d="M111.388 14.8439V5.7207H114.309V28.8161H111.388V26.526C110.724 27.3923 109.862 28.0879 108.876 28.5555C107.889 29.023 106.805 29.249 105.714 29.2147C104.608 29.2446 103.507 29.0394 102.486 28.6126C101.465 28.1859 100.546 27.5474 99.7895 26.7392C98.2432 25.0794 97.4108 22.8785 97.4716 20.6108C97.4401 19.5015 97.6288 18.3969 98.0266 17.3609C98.4245 16.325 99.0236 15.3781 99.7895 14.575C100.529 13.7606 101.435 13.1153 102.446 12.6833C103.457 12.2513 104.55 12.0426 105.649 12.0717C106.756 12.0511 107.853 12.2912 108.85 12.7728C109.847 13.2544 110.716 13.9639 111.388 14.8439V14.8439ZM111.601 20.6108C111.646 19.0784 111.087 17.5899 110.044 16.4664C109.539 15.9017 108.916 15.4553 108.219 15.1588C107.522 14.8624 106.768 14.7232 106.011 14.7512C105.251 14.7255 104.495 14.8657 103.796 15.1619C103.096 15.4581 102.469 15.9033 101.959 16.4664C100.939 17.602 100.392 19.0847 100.429 20.6108C100.388 22.1542 100.935 23.6557 101.959 24.8108C102.47 25.3722 103.097 25.8156 103.797 26.1102C104.497 26.4048 105.252 26.5435 106.011 26.5167C106.767 26.5465 107.521 26.4091 108.218 26.1142C108.915 25.8193 109.538 25.3743 110.044 24.8108C111.092 23.668 111.651 22.1605 111.601 20.6108V20.6108Z"
                    fill="#0482B0"
                />
                <path
                    d="M120.354 10.1428C120.072 10.1519 119.791 10.1041 119.528 10.0021C119.266 9.90013 119.026 9.74617 118.824 9.54943C118.623 9.35407 118.465 9.11849 118.362 8.85808C118.258 8.59767 118.21 8.31829 118.221 8.03817C118.213 7.76532 118.262 7.49378 118.366 7.24133C118.47 6.98888 118.626 6.76122 118.824 6.57327C119.239 6.18873 119.788 5.98233 120.354 5.99843C120.625 5.99386 120.895 6.04405 121.147 6.146C121.398 6.24795 121.627 6.39957 121.819 6.59181C122.016 6.77719 122.172 7.00154 122.277 7.25056C122.382 7.49958 122.435 7.76781 122.431 8.03817C122.437 8.31391 122.388 8.58809 122.286 8.84442C122.184 9.10075 122.032 9.33401 121.838 9.53033C121.644 9.72665 121.413 9.88203 121.158 9.98724C120.903 10.0925 120.63 10.1454 120.354 10.1428V10.1428ZM121.754 12.47V28.8157H118.824V12.47H121.754Z"
                    fill="#0482B0"
                />
                <path
                    d="M135.893 25.5799L136.987 27.8422C135.733 28.7826 134.196 29.2667 132.629 29.2144C131.902 29.2648 131.173 29.1481 130.498 28.8733C129.823 28.5985 129.22 28.1728 128.735 27.6289C127.773 26.3172 127.304 24.7089 127.409 23.0859V14.964H124.507V12.47H127.437V6.66602H130.33V12.47H136.125V14.964H130.33V23.3084C130.33 25.4594 131.214 26.5349 132.981 26.5349C134.023 26.4994 135.033 26.1681 135.893 25.5799V25.5799Z"
                    fill="#0482B0"
                />
                <path
                    d="M148.873 25.5799L149.967 27.8422C148.713 28.7826 147.176 29.2667 145.609 29.2144C144.882 29.2634 144.154 29.1461 143.479 28.8714C142.804 28.5967 142.201 28.1717 141.715 27.6289C140.753 26.3172 140.284 24.7089 140.389 23.0859V14.964H137.487V12.47H140.417V6.66602H143.31V12.47H149.105V14.964H143.31V23.3084C143.31 25.4594 144.194 26.5349 145.961 26.5349C147.003 26.4994 148.013 26.1681 148.873 25.5799V25.5799Z"
                    fill="#0482B0"
                />
                <path
                    d="M167.305 20.3324C167.305 20.5364 167.305 21.0371 167.212 21.8344H154.232C154.402 23.1982 155.094 24.4431 156.162 25.3075C157.231 26.1719 158.592 26.5887 159.962 26.4702C160.989 26.4623 162.009 26.2934 162.984 25.9695C163.945 25.6733 164.853 25.2258 165.673 24.6437L166.925 26.8689C165.947 27.5871 164.861 28.1443 163.707 28.5192C162.474 28.9518 161.176 29.1744 159.869 29.1775C158.517 29.1993 157.174 28.9503 155.919 28.445C154.858 28.0189 153.919 27.3374 153.184 26.4609C152.52 25.629 152.005 24.6878 151.664 23.6795C151.313 22.669 151.137 21.606 151.144 20.5364C151.072 18.2803 151.896 16.0872 153.435 14.4357C154.205 13.6355 155.137 13.0071 156.167 12.5917C157.198 12.1763 158.304 11.9832 159.415 12.0251C160.463 11.9862 161.507 12.1638 162.484 12.5466C163.46 12.9295 164.347 13.5094 165.089 14.2503C165.854 15.0593 166.445 16.0162 166.826 17.0622C167.207 18.1082 167.37 19.2211 167.305 20.3324ZM154.186 19.3033H164.282C164.274 18.6764 164.14 18.0574 163.889 17.4829C163.638 16.9084 163.275 16.3898 162.821 15.9579C162.366 15.5259 161.83 15.1892 161.243 14.9676C160.657 14.746 160.032 14.644 159.405 14.6675C158.141 14.6456 156.916 15.1102 155.984 15.9655C155.024 16.8427 154.39 18.0193 154.186 19.3033V19.3033Z"
                    fill="#0482B0"
                />
                <path
                    d="M173.674 28.816H170.753V12.4703H173.618V14.7882C174.128 13.8798 174.887 13.1357 175.805 12.6431C176.723 12.1504 177.762 11.9297 178.801 12.0067C179.653 11.9803 180.501 12.1233 181.297 12.4272C182.092 12.7312 182.82 13.1902 183.437 13.7776C184.084 14.4517 184.583 15.2534 184.902 16.1315C185.222 17.0096 185.354 17.9447 185.291 18.8769V28.816H182.389V19.7299C182.482 18.395 182.076 17.0733 181.249 16.0213C180.873 15.5875 180.405 15.244 179.878 15.016C179.352 14.7881 178.781 14.6817 178.208 14.7047C177.594 14.6898 176.985 14.8081 176.421 15.0514C175.858 15.2947 175.354 15.6572 174.944 16.114C174.093 17.1149 173.646 18.3981 173.692 19.7113L173.674 28.816Z"
                    fill="#0482B0"
                />
                <path
                    d="M198.995 25.5799L200.098 27.8422C198.841 28.7839 197.301 29.2681 195.731 29.2144C195.003 29.2588 194.274 29.1356 193.6 28.8544C192.927 28.5731 192.327 28.1412 191.846 27.5918C190.881 26.2819 190.411 24.6723 190.521 23.0488V14.964H187.619V12.47H190.595V6.66602H193.376V12.47H199.171V14.964H193.376V23.3084C193.376 25.4594 194.263 26.5349 196.037 26.5349C197.095 26.5114 198.123 26.1795 198.995 25.5799V25.5799Z"
                    fill="#0482B0"
                />
                <path
                    d="M201.776 27.3232C201.771 27.072 201.819 26.8226 201.917 26.5911C202.014 26.3595 202.159 26.151 202.342 25.9788C202.718 25.6248 203.216 25.4277 203.733 25.4277C204.249 25.4277 204.747 25.6248 205.123 25.9788C205.306 26.151 205.451 26.3595 205.548 26.5911C205.646 26.8226 205.694 27.072 205.689 27.3232C205.697 27.5857 205.65 27.8471 205.553 28.0911C205.456 28.3351 205.31 28.5565 205.123 28.7417C204.752 29.1064 204.253 29.3107 203.733 29.3107C203.212 29.3107 202.713 29.1064 202.342 28.7417C202.155 28.5565 202.009 28.3351 201.912 28.0911C201.815 27.8471 201.768 27.5857 201.776 27.3232V27.3232Z"
                    fill="#0482B0"
                />
                <path
                    d="M216.555 29.1865C215.408 29.2293 214.265 29.0376 213.195 28.6231C212.126 28.2085 211.152 27.58 210.333 26.7759C208.775 25.1084 207.908 22.9113 207.908 20.6289C207.908 18.3465 208.775 16.1494 210.333 14.4819C211.778 13.1791 213.593 12.3586 215.525 12.1342C217.457 11.9099 219.412 12.293 221.116 13.2302C222.405 13.9335 223.445 15.0184 224.092 16.3362L221.682 17.7455C221.163 16.8339 220.415 16.0733 219.512 15.5388C218.655 15.0164 217.67 14.7405 216.666 14.7415C215.888 14.728 215.116 14.874 214.397 15.1706C213.677 15.4672 213.027 15.9081 212.484 16.466C211.449 17.6087 210.875 19.0958 210.875 20.6382C210.875 22.1805 211.449 23.6677 212.484 24.8104C213.003 25.3655 213.635 25.8032 214.337 26.0942C215.039 26.3853 215.795 26.5228 216.555 26.4978C217.594 26.4957 218.613 26.2104 219.503 25.6726C220.391 25.1824 221.123 24.4533 221.617 23.568L223.972 24.9772C223.274 26.2691 222.213 27.3292 220.922 28.0276C219.588 28.7788 218.085 29.1777 216.555 29.1865V29.1865Z"
                    fill="#0482B0"
                />
                <path
                    d="M234.755 29.2147C233.616 29.2437 232.483 29.0391 231.426 28.6133C230.368 28.1876 229.41 27.5497 228.608 26.7392C227.819 25.9296 227.199 24.9705 226.785 23.9181C226.371 22.8657 226.171 21.7413 226.198 20.6107C226.17 19.4884 226.37 18.372 226.784 17.3285C227.198 16.2851 227.819 15.3359 228.608 14.5379C230.258 12.9334 232.468 12.0356 234.769 12.0356C237.07 12.0356 239.281 12.9334 240.93 14.5379C242.495 16.1837 243.367 18.3677 243.367 20.6385C243.367 22.9094 242.495 25.0934 240.93 26.7392C240.13 27.5582 239.167 28.2014 238.104 28.6276C237.041 29.0538 235.9 29.2538 234.755 29.2147V29.2147ZM240.392 20.6107C240.41 19.8453 240.273 19.0842 239.992 18.3724C239.71 17.6606 239.288 17.0125 238.751 16.4663C238.241 15.9165 237.623 15.4778 236.935 15.1779C236.248 14.8779 235.506 14.7231 234.755 14.7231C234.005 14.7231 233.263 14.8779 232.576 15.1779C231.888 15.4778 231.27 15.9165 230.759 16.4663C230.223 17.0075 229.801 17.6506 229.518 18.3576C229.234 19.0646 229.095 19.8213 229.109 20.5829C229.088 21.3621 229.223 22.1376 229.507 22.8637C229.79 23.5898 230.216 24.2518 230.759 24.8107C231.271 25.3581 231.89 25.7946 232.577 26.093C233.265 26.3913 234.006 26.5453 234.755 26.5453C235.505 26.5453 236.246 26.3913 236.934 26.093C237.621 25.7946 238.24 25.3581 238.751 24.8107C239.288 24.2534 239.71 23.5954 239.991 22.8746C240.273 22.1538 240.409 21.3844 240.392 20.6107V20.6107Z"
                    fill="#0482B0"
                />
                <path
                    d="M265.694 12.0344C266.578 12.0003 267.46 12.1545 268.28 12.487C269.1 12.8195 269.84 13.3226 270.451 13.9629C271.692 15.3729 272.328 17.2147 272.221 19.0901V28.8159H269.292V19.5444C269.373 18.2755 268.998 17.0194 268.235 16.0027C267.882 15.5867 267.439 15.2574 266.939 15.0404C266.439 14.8233 265.896 14.7243 265.351 14.751C264.761 14.7344 264.175 14.846 263.632 15.0781C263.089 15.3102 262.604 15.6573 262.208 16.0954C261.397 17.077 260.986 18.3287 261.058 19.6V28.8159H258.156V19.6C258.235 18.3243 257.857 17.0624 257.09 16.0397C256.739 15.615 256.294 15.2776 255.791 15.0539C255.287 14.8303 254.739 14.7266 254.188 14.751C253.595 14.7347 253.006 14.8483 252.461 15.0837C251.917 15.3191 251.43 15.6707 251.036 16.1139C250.184 17.095 249.742 18.3667 249.803 19.6649V28.8159H246.873V12.4702H249.738V14.8808C250.19 14.0001 250.881 13.2643 251.731 12.7576C252.616 12.2518 253.624 12.0015 254.643 12.0344C255.723 12.026 256.786 12.3077 257.721 12.8503C258.631 13.3541 259.35 14.1422 259.77 15.094C260.343 14.1305 261.183 13.3542 262.19 12.8596C263.276 12.3099 264.477 12.0271 265.694 12.0344V12.0344Z"
                    fill="#0482B0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.69008 26.1921L25.0146 22.1497C26.0796 21.9018 27.0323 21.3078 27.7235 20.4605C28.4148 19.6132 28.8055 18.5607 28.8345 17.4676L28.8901 3.29142C28.9191 2.80942 28.8356 2.3273 28.6463 1.88309C28.4569 1.43889 28.167 1.04478 27.7992 0.731875C27.4315 0.418968 26.996 0.195831 26.5272 0.0800841C26.0584 -0.035663 25.5692 -0.0408521 25.098 0.0649254L3.77352 4.11658C1.91922 4.45963 0.0649123 6.10068 0.0185547 8.00135V15.3166C0.407959 13.147 1.97485 11.3298 5.02519 10.6808L14.5007 8.88214V5.89671L25.7378 10.9868L14.4358 20.8795V17.3934L6.74969 18.7285C4.07949 19.2384 0.0649157 21.1206 0.630479 24.4212C0.917896 26.0715 2.08611 26.4702 3.66227 26.1828L3.69008 26.1921Z"
                    fill="url(#paint0_linear_23_651)"
                />
                <path
                    d="M30.8092 8.78964L30.0118 8.93799V18.4784C29.9728 19.5634 29.5775 20.6054 28.8871 21.4433C28.1967 22.2812 27.2495 22.8684 26.192 23.1141L6.09131 26.9433L6.33236 33.1459C6.61978 34.7963 7.788 35.2042 9.36416 34.9075L30.6887 30.8744C31.7529 30.6251 32.7048 30.0306 33.3957 29.1837C34.0867 28.3367 34.478 27.2849 34.5085 26.1923L34.5641 12.0161C34.5916 11.538 34.5085 11.06 34.3212 10.6192C34.1339 10.1784 33.8475 9.78673 33.4843 9.47461C33.121 9.16249 32.6908 8.93832 32.2268 8.81953C31.7628 8.70073 31.2777 8.69051 30.8092 8.78964V8.78964Z"
                    fill="#0482B0"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_23_651"
                    x1="1.1641e-05"
                    y1="13.1378"
                    x2="28.8993"
                    y2="13.1378"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0296CC" />
                    <stop offset="1" stopColor="#00A9DB" />
                </linearGradient>
                <clipPath id="clip0_23_651_z">
                    <rect width="272.249" height="35" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
